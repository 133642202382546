import { useCallback, useMemo, useState } from 'react'
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Layout, MenuProps } from 'antd'

import DoubleArrowIcon from '@/assets/icons/ic_double_arrow.svg'
import paths from '@/routes/paths'
import Header from '@/layout/Header'
import PharmLogo from '@/utils/icons/logo'
import LogoIcon from '@/assets/icons/logoIcon.svg'
import { menuItemsData } from './data'
import { CollapsedToggle, CustomMenu } from './styles'
import { roleBasedValue } from '@/auth/roleBasedValue'
import { useAppSelector } from '@/redux/store'

const { Content, Sider } = Layout

const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  const { isAuthenticated, userRole } = useAppSelector((state) => state.auth.auth)

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev)
  }, [])

  const getMenuKey = useCallback((path: string) => path.split('/')[1] || 'statistics', [])

  const defaultSelectedKey = useMemo(() => {
    return [getMenuKey(location.pathname)]
  }, [location.pathname])

  type MenuItem = Required<MenuProps>['items'][number]

  const items: MenuItem[] = useMemo(
    () =>
      menuItemsData.map((item) => {
        if (item.children) {
          return {
            key: item.key,
            label: item.label,
            icon: <div onClick={() => navigate(item.path)}>{item.icon}</div>,
            children: item.children.map((subItem) =>
              roleBasedValue(
                {
                  key: subItem.key,
                  label: (
                    <Link to={subItem.path} className="flex items-center gap-2.5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                        <circle cx="2.5" cy="2.5" r="2.5" />
                      </svg>
                      <span>{subItem.label}</span>
                    </Link>
                  ),
                },
                item.allowedRoles,
                userRole,
              ),
            ),
          }
        }
        return roleBasedValue(
          {
            key: item.key,
            label: <Link to={item.path}>{item.label}</Link>,
            icon: <div onClick={() => navigate(item.path)}>{item.icon}</div>,
          },
          item.allowedRoles,
          userRole,
        )
      }),
    [navigate],
  )

  if (!isAuthenticated) {
    return <Navigate to={paths.HOME} />
  }

  return (
    <Layout className="h-screen w-screen !overflow-hidden">
      <Sider trigger={null} collapsible collapsed={collapsed} width={272}>
        <div className="h-[7px] bg-primary"></div>
        <CollapsedToggle onClick={toggleCollapsed} aria-label="Toggle sidebar">
          <img className={collapsed ? 'rotate-180 transform' : ''} src={DoubleArrowIcon} alt="arrow" />
        </CollapsedToggle>
        <Link
          to={paths.STATISTICS}
          className={`flex min-h-[87px] items-center justify-center bg-white ${collapsed ? 'p-3' : 'pb-10 pl-[30px] pr-[22px] pt-[43px]'}`}
        >
          {collapsed ? <img src={LogoIcon} alt="Logo icon" /> : <PharmLogo size={220} />}
        </Link>
        <CustomMenu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={defaultSelectedKey}
          style={{ height: '100%' }}
          items={items}
          $isCollapsed={collapsed}
        />
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: 48,
            margin: 0,
            minHeight: 280,
            background: 'transparent',
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
