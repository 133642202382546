export type Role = 'admin' | 'expert' | 'accountant' | 'organizer' | 'lawyer' | 'director' | 'hr'

export enum roles {
  ADMIN = 'admin',
  EXPERT = 'expert',
  ACCOUNTANT = 'accountant',
  ORGANIZER = 'organizer',
  LAWYER = 'lawyer',
  DIRECTOR = 'director',
  HR = 'hr',
}

export interface User {
  id: string
  name: string
  username: string
  email: string
  role: Role
}

export interface RoutePermission {
  path: string
  allowedRoles: Role[]
}
