import { createSlice } from '@reduxjs/toolkit'
// import { eImzoLoginThunk } from '../thunks/eImzoLoginThunk'
// import { TokenService } from '@/utils/storage'
import { Role, roles } from '@/types/auth'

export type AuthState = {
  auth: {
    // token: string
    // refreshToken: string
    // status: 'loading' | 'idle' | 'failed' | 'success'
    isAuthenticated: boolean
    userRole: Role | null
  }
}

const defaultState: AuthState = {
  auth: {
    // token: '',
    // refreshToken: '',
    // status: 'idle',
    isAuthenticated: true,
    userRole: roles.ADMIN,
  },
}

const slice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    logout: (state) => {
      // state.auth = { ...defaultState.auth }
      // TokenService.clearTokens()
      state.auth.isAuthenticated = false
      state.auth.userRole = null
    },
    login: (state, action) => {
      state.auth.isAuthenticated = true
      state.auth.userRole = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(eImzoLoginThunk.pending, (state) => {
  //     state.auth.status = 'loading'
  //   })
  //   builder.addCase(eImzoLoginThunk.fulfilled, (state, action) => {
  //     state.auth.token = action.payload.token
  //     state.auth.refreshToken = action.payload.refreshToken
  //     state.auth.status = 'success'
  //   })
  //   builder.addCase(eImzoLoginThunk.rejected, (state) => {
  //     state.auth.status = 'failed'
  //   })
  // },
})

const { actions, reducer } = slice
export const { logout, login } = actions
export default reducer
