import React from 'react'
import { Navigate } from 'react-router-dom'
import { Role } from '../types/auth'
import paths from '../routes/paths'
import { useAppSelector } from '@/redux/store'
import { getAuthState } from '@/redux/selector/auth'

interface ProtectedRouteProps {
  allowedRoles?: Role[]
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, children }) => {
  const { isAuthenticated, userRole } = useAppSelector(getAuthState)

  if (!isAuthenticated) {
    return <Navigate to={paths.HOME} />
  }

  if (!allowedRoles?.includes(userRole!)) {
    return <Navigate to={paths.UNAUTHORIZED} />
  }

  return <>{children}</>
}

export default ProtectedRoute
