// general routes
export const NOT_FOUND = '*'
export const UNAUTHORIZED = '/unauthorized'
export const HOME = '/'

// dashboard routes
const STATISTICS = '/statistics'
const APPLICATIONS = '/applications'
const APPLICATION_DETAILS = '/applications/:id'
const APPLICATION_STEPS = '/applications/:id/steps'
const REVIEW_APPLICATION = '/applications/:id/review'
const AGREEMENTS = '/agreements'
const ORDERS = '/orders'
const INSPECTION_CASES = '/inspectionCases'
const PHARM_INSPECTIONS = '/pharmInspections'
const CONCLUSIONS = '/conclusions'
const CAPA_PLAN = '/capaPlan'
const CERTIFICATE_REGISTRY = '/certificateRegistry'
const EMPLOYEES = '/employees'
const EMPLOYEE_INFO = '/employees/:id'
const EMPLOYEE_ADD = '/employees/add'
const REPORTS = '/reports'

const paths = {
  NOT_FOUND,
  UNAUTHORIZED,
  HOME,
  STATISTICS,
  APPLICATIONS,
  APPLICATION_DETAILS,
  APPLICATION_STEPS,
  REVIEW_APPLICATION,
  AGREEMENTS,
  ORDERS,
  INSPECTION_CASES,
  PHARM_INSPECTIONS,
  CONCLUSIONS,
  CAPA_PLAN,
  CERTIFICATE_REGISTRY,
  EMPLOYEES,
  EMPLOYEE_INFO,
  EMPLOYEE_ADD,
  REPORTS,
}

export default paths
